import { makeStyles } from '@admitkard/ccl/style';
import { useRive } from '@rive-app/react-canvas';
import Image from 'next/image';
import React from 'react';

const useStyles = makeStyles(
  (theme) => ({
    outerContainer: {
      width: '100%',
      boxSizing: 'border-box',
      color: theme.colorPalette.indigo.main,
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 400,
    },
    innerContainer: {
      backgroundColor: 'hsla(0, 0%, 98%, 1)',
      borderRadius: '1rem',
      margin: '0 1rem',
      '@media screen and (min-width: 992px)': {
        backgroundColor: 'unset',
        margin: '0',
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1rem',
      marginBottom: '2rem',
      '@media screen and (min-width: 992px)': {
        alignItems: 'center',
        marginBottom: '0',
        backgroundColor:'#FFFFFF',
      },
    },
    mentorXText: {
      fontSize: '1.25rem',
      '@media screen and (min-width: 992px)': {
        fontSize: '2rem',
      },
    },
    mentorXTextHighlight: {
      fontSize: '1.25rem',
      textTransform: 'capitalize',
      fontWeight: 700,
      color: 'hsla(266, 100%, 67%, 1)',
      '@media screen and (min-width: 992px)': {
        textTransform: 'uppercase',
        fontSize: '2rem',
      },
    },
    subText: {
      fontSize: '1.25rem',
      '@media screen and (min-width: 992px)': {
        fontSize: '1.5rem',
      },
    },
    subTextHighlight: {
      color: 'hsla(266, 100%, 67%, 1)',
    },
    subTextLine3: {
      display: 'block',
      '@media screen and (min-width: 992px)': {
        display: 'inline',
      },
    },
    subTextGraphic: {
      display: 'none',
      '@media screen and (min-width: 992px)': {
        display: 'inline',
      },
    },
    graphicsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transform: 'translateY(-3.125rem)',
    },
    graphicImgContainer: {
      display: 'none',
      '@media screen and (min-width: 992px)': {
        display: 'inline',
      },
    },
    slideRiveAnimationDesktop: {
      width: '100%',
      height: '17.375rem',
      '--float-threshold': '-3.75rem',
      transform: 'translateY(var(--float-threshold))',
      marginBottom: 'calc(2 * var(--float-threshold))',
      display: 'none',
      '@media screen and (min-width: 992px)': {
        display: 'block',
      },
    },
    slideRiveAnimationMobile: {
      aspectRatio: '179/78',
      display: 'block',
      marginBottom: '0.25rem',
      '@media screen and (min-width: 992px)': {
        display: 'none',
      },
    },
    cardButtonContainer: {
      zIndex: 1,
      height: '3rem',
      width: 'max-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1rem 1rem 1rem 1.25rem',
      gap: '0.75rem',
      background: theme.colorPalette.indigo.main,
      borderRadius: '1.875rem',
      boxSizing: 'border-box',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontWeight: 400,
      color: '#F7EFFF',
      border: 'none',
      textDecoration: 'none',
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
      },
      '@media screen and (min-width: 992px)': {
        fontSize: '1.1rem',
        height: '3.5rem',
      },
    },
    cardButtonIconContainer: {
      height: '1.25rem',
      aspectRatio: '1',
      '@media screen and (min-width: 992px)': {
        height: '1.5rem',
      },
      '& svg': {
        height: 'inherit',
        width: 'inherit',
      },
    },
  }),
  { name: 'MentorXSection' }
);

const MentorXSection: React.FunctionComponent = () => {
  const classes = useStyles();

  const { RiveComponent: MentorXSliderDesktop } = useRive({
    src: 'https://assets.admitkard.com/rive/homepage_desktop_mentorx_slide.riv',
    autoplay: true,
  });

  const { RiveComponent: MentorXSliderMobile } = useRive({
    src: 'https://assets.admitkard.com/rive/homepage_mobile_mentorx_slide2.riv',
    autoplay: true,
  });

  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <div className={classes.headerContainer}>
          <div className="v-space" style={{ height: '1.5rem' }} />
          <span className={classes.mentorXText}>
            mentor<span className={classes.mentorXTextHighlight}>x</span>
          </span>
          <div className="v-space" style={{ height: '1rem' }} />
          <span className={classes.subText}>
            <span className={classes.subTextHighlight}>one stop solution</span> {`for all `}
            <span className={classes.subTextLine3}>your study abroad needs</span>
          </span>
          <div className="v-space" style={{ height: '2.25rem' }} />
          {/* Don't remove the cose now */}
          {/* <a id="cta-mentorX" href="/mentorx" className={classes.cardButtonContainer}>
            Join MentorX
            <span className={classes.cardButtonIconContainer}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#F7EFFF" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
                  fill="#483287"
                />
              </svg>
            </span>
          </a> */}
        </div>
        <div className={classes.graphicsContainer}>
          <div className={classes.graphicImgContainer}>
            <Image
              width={849}
              height={328}
              className={classes.subTextGraphic}
              src="https://assets.admitkard.com/images/image-home-page-mentorx-uk-us-min.webp"
            />
          </div>
          <MentorXSliderDesktop className={classes.slideRiveAnimationDesktop} />
        </div>
        <MentorXSliderMobile className={classes.slideRiveAnimationMobile} />
      </div>
    </div>
  );
};

export default MentorXSection;
